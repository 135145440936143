<template>
  <div class="order-list">
    <table class="order-table">
      <thead>
        <tr>
          <th>服务信息</th>
          <th>单价/数量</th>
          <th>服务商品类别</th>
          <th>总价</th>
          <th>收货人</th>
          <th>处理时间</th>
          <th>订单状态</th>
          <th>操作</th>
        </tr>
        <tr style="height: 10px"></tr>
      </thead>
      <tbody v-if="!list.length">
        <tr style="background: #fff">
          <td colspan="9">
            <No />
          </td>
        </tr>
      </tbody>
      <tbody v-for="(item, index) in list" :key="index">
        <tr>
          <td colspan="9">
            <div class="order-head">
              <div class="left">
                <span class="sn">订单编号：{{ item.pay_sn }}</span>
                <div v-if="item.member_id" style="margin-right: 10px">
                  <el-tooltip placement="top">
                    <el-tag type="success">门店订单</el-tag>
                    <template slot="content">{{ item.shop_info.store_name }}</template>
                  </el-tooltip>
                </div>
                <el-tag v-else type="primary" style="margin-right: 10px">总店订单</el-tag>
              </div>
              <div class="right">
                <el-button type="text" size="small" @click="orderInfo(item.id)">订单详情</el-button>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div class="goods-info">
              <div class="item" v-for="goodsInfo in item.extend_order_goods" :key="goodsInfo.id">
                <el-image :src="goodsInfo.goods_img"></el-image>
                <div class="goods-title">
                  <p>{{ goodsInfo.goods_title }}</p>
                  <p v-if="goodsInfo.sku_list">规格：{{ goodsInfo.sku_list.title }}</p>
                </div>
              </div>
            </div>
          </td>
          <td>
            <div class="goods-price">
              <p v-for="goodsPrice in item.extend_order_goods" :key="goodsPrice.id">
                <span>￥{{ goodsPrice.goods_price }}</span>
                <span>x{{ goodsPrice.goods_num }}</span>
              </p>
            </div>
          </td>
          <td>
            <div style="line-height: 70px" v-for="goodsInfo in item.extend_order_goods" :key="goodsInfo.id">{{ goodsInfo.classify_name }}</div>
          </td>
          <td>
            <div class="freight_fee">
              <p>
                ￥{{ item.amount }}
                <img v-show="item.goods_type == 2" src="@/assets/image/tuan.png" alt="" />
                <el-tooltip
                  placement="top"
                  v-if="
                    Number(item.revise_amount) > 0 ||
                    Number(item.revise_freight_fee) > 0 ||
                    Number(item.discount_reduction_amount) > 0 ||
                    Number(item.pd_amount) > 0 ||
                    Number(item.reduction_amount) > 0
                  "
                >
                  <div slot="content">
                    <template v-if="Number(item.revise_amount) > 0">
                      <span>改价后：￥{{ Number(item.revise_amount) }}</span>
                      <br />
                    </template>
                    <template v-if="Number(item.revise_freight_fee) > 0">
                      <span>运费改价后：￥{{ item.revise_freight_fee }}元</span>
                      <br />
                    </template>
                    <template v-if="Number(item.discount_reduction_amount) > 0">
                      <span>{{ item.preference_type == 'distribution' ? '等级优惠：' : '会员立减：' }}￥{{ item.discount_reduction_amount }}</span>
                      <br />
                    </template>
                    <template v-if="Number(item.reduction_amount) > 0">
                      <span>优惠券立减：￥{{ item.reduction_amount }}</span>
                      <br />
                    </template>
                    <span v-show="Number(item.pd_amount) > 0">会员余额立减：￥{{ item.pd_amount }}</span>
                  </div>
                  <span>
                    <i class="el-icon-warning-outline"></i>
                  </span>
                </el-tooltip>
              </p>
              <p v-if="item.freight_fee">（运费：{{ parseFloat(item.freight_fee) - parseFloat(item.freight_reduction_amount) }}元）</p>
            </div>
          </td>
          <td class="consignee">
            <p>
              {{ item.extend_order_extend.reciver_name }}
              <img v-show="item.goods_type == 2 && item.group_identity == 1" src="@/assets/image/yuan.png" alt="" />
            </p>
            <p>{{ item.extend_order_extend.receiver_phone }}</p>
          </td>
          <td>
            {{ getDateformat(item.create_time) }}
          </td>
          <td>
            <p v-if="item.goods_type == 2" style="margin-bottom: 5px">
              <el-tag type="warning" size="small">团号：{{ item.group_sign }}</el-tag>
            </p>
            <span
              :style="{
                color: item.state == 0 || item.state == 10 || item.state == 40 ? '#409EFF' : item.state == 20 ? '#f56c6c' : '#e6a23c',
              }"
            >
              <span>{{ item.state == 0 ? '已关闭' : item.state == 10 ? '待支付' : item.state == 20 ? '待发送' : item.state == 30 ? '待确认' : '已完成' }}</span>
            </span>
          </td>
          <td>
            <el-button v-if="item.state == 20" type="primary" size="small" @click.stop="orderSend(item)">发送账号</el-button>
            <el-button v-if="item.state == 10" type="text" size="small" @click.stop="cancelOrder(item)">取消订单</el-button>
          </td>
        </tr>
      </tbody>
    </table>
    <Paging :total="total" :page="content.page" :pageNum="content.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="发送账号" :visible.sync="showOrderSend" width="600px">
      <el-form label-width="70px">
        <el-form-item label="账号：">
          <el-input v-model="account_name"></el-input>
        </el-form-item>
        <el-form-item label="密码：">
          <el-input v-model="account_password"></el-input>
        </el-form-item>
        <el-form-item label="网址：">
          <el-input v-model="website"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showOrderSend = !1">取 消</el-button>
        <el-button type="primary" @click="confirmSend">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="取消订单" :visible.sync="showCancelOrder" width="35%">
      <el-form v-if="currentSelectOrderInfo.id" label-width="85px">
        <el-form-item label="取消原因：">
          <el-select v-model="cancel_reason_id" filterable placeholder="请选择取消原因">
            <el-option v-for="item in cancelList" :key="item.id" :label="item.title" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="取消说明：">
          <el-input v-model="state_remark" type="textarea" :rows="8" placeholder="请输入取消说明"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCancelOrder = !1">取 消</el-button>
        <el-button type="primary" @click="comfirmCancelOrder">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
import { mapState } from 'vuex';
const industryName = '/Gateway'; //行业版本接口名称（用于更换重复接口）
export default {
  props: {
    content: {
      type: Object,
    },
  },
  data() {
    return {
      total: 0,
      list: [],
      showCancelOrder: !1,
      currentSelectOrderInfo: {},
      showOrderSend: !1,
      order_id: '', //订单id
      cancel_reason_id: '', //取消原因id
      cancelInfo: [], //取消信息
      state_remark: '', //取消说明
      account_name:'',//账号
      account_password:'',//密码
      website:'',//网址
    };
  },
  components: {
    Paging: Paging,
  },
  computed: {
    ...mapState({
      cancelList: state => state.order.cancelList,
    }),
  },
  watch: {
    showCancelOrder(val) {
      if (val && !this.cancelList.length) this.$store.dispatch('order/getCancelList', { type: 4 });
    },
  },
  created() {
    let utils = this.$store.state.utils;
    if (utils.is_record) {
      this.content.page = utils.page;
      this.content.rows = utils.rows;
      utils.is_record = !1;
    }
    this.getList();
  },
  methods: {
    updateData(val, status) {
      if (status == 0) {
        this.content.rows = val;
      } else {
        this.content.page = val;
      }
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    orderInfo(id) {
      this.$router.push({
        path: '/order/gatewayServiceOrderInfo',
        query: {
          id: id,
          page: this.content.page,
          rows: this.content.rows,
        },
      });
    },
    //取消订单
    cancelOrder(row) {
      this.currentSelectOrderInfo = row;
      this.state_remark = '';
      this.showCancelOrder = !0;
    },
    comfirmCancelOrder() {
      if (!this.cancel_reason_id)
        return this.$message({
          message: '请选择取消原因',
          type: 'warning',
        });
      if (!this.state_remark)
        return this.$message({
          message: '请填写取消说明',
          type: 'warning',
        });
      this.$axios
        .post(industryName + this.$api.order.cancel, {
          id: this.currentSelectOrderInfo.id,
          state_remark: this.state_remark,
          cancel_reason_id: this.cancel_reason_id,
        })
        .then(res => {
          if (res.code == 0) {
            this.showCancelOrder = !1;
            this.$message({
              message: '订单已取消',
              type: 'success',
            });
            this.getList();
            this.$parent.$parent.getOrderNum();
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    //发送账号
    orderSend(row) {
      this.currentSelectOrderInfo = row;
      this.account_name = '';
      this.account_password = '';
      this.website = '';
      this.showOrderSend = !0;
    },
    confirmSend() {
        if(!this.account_name) return this.$message.warning("请填写账号");
        if(!this.account_password) return this.$message.warning("请填写密码");
        if(!this.website) return this.$message.warning("请填写网址");
        this.$axios.post(industryName + this.$api.order.setSend,{
          id:this.currentSelectOrderInfo.id,
          account_name:this.account_name,
          account_password:this.account_password,
          website:this.website
        }).then(res=>{
          if(res.code == 0){
            this.showOrderSend = !1;
            this.$message.success("发送成功");
            this.getList();
          }else{
            this.$message.error(res.msg);
          }
        })
    },
    getList() {
      let content = this.content;
      let data = {
        order_type: 4,
        page: content.page,
        rows: content.rows,
        create_time: content.create_time,
      };
      if (content.state_type == 'group_state_pay') {
        data.group_state_type = 'group_state_pay';
        data.state_type = 'all';
      } else {
        data.state_type = content.state_type;
      }
      if (content.feedback_state != 'whole') {
        data.feedback_state = content.feedback_state;
      }
      if (content.keywords) {
        data.keywords_type = content.keywords_type;
        data.keywords = content.keywords;
      }
      if (content.room_id) {
        data.room_id = content.room_id;
        data.goods_id = content.goods_id;
      }
      if (this.$route.query.user_ids) data.user_ids = this.$route.query.user_ids;
      this.$axios.post(industryName + this.$api.order.list, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          for (let i in list) {
            list[i].order_status = list[i].state == 0 ? '已关闭' : list[i].state == 10 ? '待支付' : list[i].state == 20 ? '待发货' : list[i].state == 30 ? '待收货' : '已完成';
            list[i].revise_freight_fee = Number(list[i].revise_freight_fee);
            list[i].revise_amount = Number(list[i].revise_amount);
            if (list[i].extend_order_extend.reciver_infos.combine_detail) {
              list[i].extend_order_extend.reciver_infos.combine_detail = list[i].extend_order_extend.reciver_infos.combine_detail.replace(/\s*/g, '');
            }
            let extend_order_goods = list[i].extend_order_goods;
            for (let y in extend_order_goods) {
              extend_order_goods[y].difference_price = extend_order_goods[y].goods_pay_price;
            }
          }
          this.list = list;
          this.total = res.result.total_number;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.order-table {
  width: 100%;
  font-size: 14px;
  tbody {
    border: 1px solid #ebeef5;
  }
  thead tr th {
    padding: 15px;
    text-align: center;
  }
  thead tr {
    background: #f8f9fa;
    th:first-child {
      width: 280px;
    }
    th:last-child {
      width: 250px;
    }
  }
  tbody tr:first-child {
    border-bottom: 1px solid #ebeef5;

    background: #f8f9fa;
    td {
      padding: 5px 20px;
    }
  }
  tbody tr:last-child {
    td {
      text-align: center;
    }
    td:first-child {
      width: 280px;
    }
    td:last-child {
      width: 250px;
    }
  }
  .goods-info {
    width: 300px;
    flex: none;
    padding-left: 20px;

    .item {
      height: 70px;
      display: flex;
      align-items: center;
      .el-image {
        width: 50px;
        height: 50px;
        margin-right: 10px;
        border-radius: 5px;
      }
      .goods-title {
        text-align: left;
        flex: 1;
        font-size: 13px;
        p:nth-child(1) {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          overflow: hidden;
        }
        p:nth-child(2) {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          margin-top: 5px;
        }
      }
    }
  }
  .order-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      .sn {
        margin-right: 70px;
      }
      div {
        display: flex;
        align-items: center;
      }
      .el-button {
        cursor: auto;
      }
    }
    .right {
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .goods-price p {
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;
    justify-content: center;
    span:nth-child(1) {
      margin-bottom: 5px;
    }
  }
  .freight_fee {
    p:nth-child(1) {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 19px;
        height: 20px;
        margin-left: 8px;
      }
    }
    i {
      font-size: 15px;
    }
  }
  .consignee p {
    &:nth-child(1) {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
        margin-left: 8px;
      }
    }
  }
}
.change-goods-info {
  display: flex;
  align-items: center;
  .el-image {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    border-radius: 5px;
  }
}
.freight-price {
  margin-top: 20px;
  .el-input {
    width: 100px;
  }
}
</style>
